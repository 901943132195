import React from 'react';
import image from '../../assets/0.png'
import './FlipCard.css'

const FlipCard = () => {
  return <div className='flip-card'>
  <div className='flip-card-inner'>
    <div className='flip-card-back'>
      <img className='test' src={image} alt="Avatar" style={{ width: "300px", height: "300px", borderRadius: "16px"}}/>
    </div>
    <div className='flip-card-front'>
      <h1>Which emotion will you get ?</h1>
      <p>Each one will be randomly</p>
      <p>picked during the mint </p>
    </div>
  </div>
</div>;
};

export default FlipCard;
