import React from 'react';
import './Desc.css';

const Desc = () => {
  return <div className='panel_desc'>
    <div className='welcome'>
        <h1>Micro NFT collection</h1>
        <h1>representing the 5 main</h1>
        <h1>emotions</h1>
    </div>
  </div>;
};

export default Desc;