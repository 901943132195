import React from 'react';

const customWrapperStyle: React.CSSProperties = {
  backgroundColor: 'transparent',
  position: 'relative',
  zIndex: 999,
  width: '100%',
  height: '100%',
  marginTop: '-100px',
  paddingTop: '5%'
}

const customWrapperContentStyle: React.CSSProperties = {
  backgroundColor: 'transparent',
  margin: 'auto',
  width: '90%', 
  position: 'relative',
}

const CustomWrapper: React.FC = ({children = null}) => {
  return (
  <div style={customWrapperStyle}>
    <div style={customWrapperContentStyle}>
      {children}
    </div>
  </div>
  )
};

export default CustomWrapper;