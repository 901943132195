import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {MdMenu, MdClose} from 'react-icons/md';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletDialogButton } from '@solana/wallet-adapter-material-ui';
import styled from 'styled-components';
import './Navbar.css'
import logo from '../../assets/logo.png';

const ConnectButton = styled(WalletDialogButton)`
  height: 40px;
  margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(45deg, #D68FD6 0%, #f0c4f0 100%);
  color: white;
  font-size: 16px;
  font-weight: bold;
`;

const Navbar = () => {

    const wallet = useWallet();

    /* Click event on the hamburger (open / close) */
    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);

    return (
        <div className='navbar'>
           <div className='container'>
               <img className="logo" src={logo} height={108} width={300} alt='MoodyCrystals'/>
               <ul className={click ? 'nav active' : 'nav'}>
                    <Link onClick={handleClick} className = 'menu-list' to='/'>
                        Mint
                   </Link>
                   <Link onClick={handleClick} className = 'menu-list' to='/about'>
                        About
                   </Link>
                   <Link onClick={handleClick} className = 'menu-list' to='/team'>
                        Team
                   </Link>
                   <li className='menu-list'>
                        {!wallet.connected ? (
                        <ConnectButton>Connect Wallet</ConnectButton>
                        ) : <ConnectButton>Connected</ConnectButton>}
                   </li>
               </ul>
               <div onClick={handleClick} className='hamburger'>
                   {click ? (<MdClose className='icon'/>) : <MdMenu className='icon'/>}
               </div>
           </div>
        </div>
    )
}

export default Navbar
