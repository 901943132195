import React from 'react';
import ProfileCard from '../components/profilecard/ProfilCard';
import './team.css'
  
const Team = () => {
  return (
    <div style={{ marginTop: 100, marginBottom: 100 }}className='main_team'>
  
    <div className='panel_team'>
        <ProfileCard name="Lix" desc="Crypto Enthusiast, taller than a llama"/>
    </div>
    <div className='panel_team'>
      <ProfileCard name="Elo" desc="Likes challenges"/>
    </div>

  </div>
  );
};
  
export default Team;