import React from 'react';
import FlipCard from '../components/flipcard/FlipCard';
import'./about.css'

const About = () => {
  return <div style={{ marginTop: 100, marginBottom: 100 }}className='main_about'>
  
    <div className='panel_card'>
        <FlipCard />
    </div>
    <div className='panel_about'>
        <h1>This micro collection</h1>
        <h1>is living on the Solana blockchain.</h1>
        <h1>Each one was hand-drawn </h1>
        <h1>and is therefore unique. </h1>
    </div>
    
</div>
};

export default About;
