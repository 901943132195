import React from 'react';
import './ProfileCard.css'

export interface ProfileProps {
    name: string;
    desc: string;
  }

const ProfileCard = (props: ProfileProps) => {
  return  (
    <div className='profile_card' style={{ width: "300px", height: "300px", borderRadius: "16px"}}>
        <div className='profile_info'>
          <h1><b>{props.name}</b></h1>
          <p>{props.desc}</p>
        </div>
    </div>);
};

export default ProfileCard;